import { Action, Reducer } from "redux";
import { RequestState } from "../sharedTypes";
import { AuditState } from "./state";
import { KnownAction } from "./actions";

const unloadedState: AuditState = {
    auditSearchRequestState: RequestState.NotStarted,
    cancelRequestState: RequestState.NotStarted,
    searchResult: undefined
};

export const reducer: Reducer<AuditState> =
    (state: AuditState | undefined, incomingAction: Action):
    AuditState => {
        if (state === undefined) {
            return unloadedState;
        }

        const action = incomingAction as KnownAction;
        switch (action.type) {
            case "REQUEST_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequest: action.searchRequest,
                    auditSearchRequestState: RequestState.InProgress,
                    cancelRequestState: RequestState.NotStarted
                };

            case "RECEIVE_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Succeeded,
                    searchResult: {
                        total: action.searchResult?.total ?? 0,
                        // converting from protobuf to Date
                        items: action.searchResult?.items.map(item => ({
                            ...item,
                            timestamp: "seconds" in item.timestamp ?
                                new Date(item.timestamp.seconds * 1000)  // Convert seconds to milliseconds
                                : item.timestamp
                        })) ?? []
                    }
                };

            case "RECEIVE_AUDIT_AGGREGATION":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Succeeded,
                    searchResult: {
                        items: state.searchResult ? state.searchResult.items : [],
                        total: state.searchResult ? state.searchResult.total : 0,
                        aggregation: action.searchResult.aggregation ?? []
                    }
                };

            case "REJECT_SEARCH_AUDITS":
                return {
                    ...state,
                    auditSearchRequestState: RequestState.Failed
                };

            default: return state;
        }
    };
