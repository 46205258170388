import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { extractAuthProps, IAuthProps } from "../store/authTypes";
import "./Layout.css";
import NavMenu from "./NavMenu";
import SideMenu from "./SideMenu";
import { getUserManager } from "../utils";

type LayoutProps = IAuthProps & { children?: React.ReactNode  }

const Layout = (props: LayoutProps) => {
    const oidcUrl = getUserManager().settings.authority;
    const year = new Date().getFullYear();
    return (
        <div className="mb-5">
            <NavMenu />

            {!props.isAuthenticated
                ? ( // Not authenticated
                    <div className="m-3">{props.children}</div>
                )
                : ( // Authenticated
                    <div className="d-flex justify-content-start m-3">
                        <div id="sidemenu" className="d-print-none"><SideMenu /></div>
                        <div id="main-content" className="ml-3 w-100">{props.children}</div>
                    </div>
                )}

            <footer className="text-center my-5 text-muted d-print-none">
            OJ Electronics A/S &copy; {year} All rights reserved
                <p>
                    <a className="btn shadow-none text-dark btn-sm mt-n1" href={`${oidcUrl}/LegalDocuments/PrivacyPolicy`}>
                    Privacy Policy
                    </a>
                    <a className="btn shadow-none text-dark btn-sm mt-n1" href={`${oidcUrl}/LegalDocuments/TermsAndConditions`}>
                    Terms and Conditions
                    </a>
                </p>
            </footer>
        </div>
    );
};

const mapStateToProps = (state: ApplicationState): IAuthProps => ({
    ...extractAuthProps(state.oidc)
});

export default connect(mapStateToProps)(Layout);
