// libs
import { Location } from "history";
import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { Dispatch } from "redux";
import { UserState } from "redux-oidc";

// components
import Layout from "./components/Layout";
import NotFound from "./components/NotFound";
import Home from "./components/Home";
import PrivacyPolicy from "./components/LegalDocuments/PrivacyPolicy";
import TermsOfUse from "./components/LegalDocuments/TermsOfUse";
import FaqPage from "./components/Faq/FaqPage";
import ConnectivityPage from "./components/Connectivity/ConnectivityPage";
import AdminToolsPage from "./components/AdminTools/AdminToolsPage";
import UsersSearchPage from "./components/UserManagement/UsersSearchPage";
import FirmwareSearchPage from "./components/FirmwareUpdates/FirmwareSearchPage";
import SessionSearchPage from "./components/FirmwareUpdates/SessionSearchPage";
import ThermostatsList from "./components/ThermostatsList/ThermostatsList";
import EditUser from "./components/UserManagement/EditUser";
import CreateUser from "./components/UserManagement/CreateUser";
import ThermostatDetailsPage from "./components/ThermostatDetails/ThermostatDetailsPage";
import FirmwareWizard from "./components/FirmwareUpdates/FirmwareWizard/FirmwareWizard";
import RetrySessionWizard from "./components/FirmwareUpdates/FirmwareWizard/RetrySessionWizard";
import CreateFirmware from "./components/FirmwareUpdates/CreateFirmware";
import FirmwareDetailsPage from "./components/FirmwareUpdates/FirmwareDetailsPage";
import EditFirmware from "./components/FirmwareUpdates/EditFirmware";
import PrivateLabelsListPage from "./components/PrivateLabels/PrivateLabelsListPage";
import PrivateLabelEditPage from "./components/PrivateLabels/PrivateLabelEditPage";
import PrivateLabelCreatePage from "./components/PrivateLabels/PrivateLabelCreatePage";
import DistributorsListPage from "./components/Distributors/DistributorsListPage";
import DistributorEditPage from "./components/Distributors/DistributorEditPage";
import DistributorCreatePage from "./components/Distributors/DistributorCreatePage";
import EmailTemplatesPage from "./components/EmailTemplates/EmailTemplatesPage";
import { CustomCallback } from "./components/CustomCallback";
import { UnauthorizedPage, Spinner } from "./components/Common";
import EditVisibility from "./components/FirmwareUpdates/FirmwareVisibility/EditVisibility";
import SessionDetailsPage from "./components/FirmwareUpdates/SessionDetailsPage";
import ThermostatSearchPage from "./components/FirmwareUpdates/ThermostatSearchPage";
import RetryThermostatsWizard from "./components/FirmwareUpdates/FirmwareWizard/RetryThermostatsWizard";
import AppVersionsPage from "./components/FirmwareUpdates/AppVersions/AppVersionsPage";
import UserInformationView from "./components/UserInformationView/UserInformationView";
import ThermostatAuditsPage from "./components/AuditLogs/ThermostatAuditsPage";
import UserAuditsPage from "./components/AuditLogs/UserAuditsPage";

// state
import { ApplicationState } from "./store";
import { CommonState } from "./store/Common/state";
import { RequestState } from "./store/sharedTypes";
import { extractAuthProps, IAuthProps } from "./store/authTypes";
import { actionCreators } from "./store/Common/actionCreators";
import { getPrivateLabelConfig } from "./PrivateLabel";
import { createCustomDataTableThemes } from "./DataTableThemes";

// style
import "./css/style.css";

type AppProps = {
    location: Location;
    oidc: UserState;
    common: CommonState;
    dispatch: Dispatch;
} & IAuthProps & typeof actionCreators;

const App = (props: AppProps) => {
    // Lookups are loaded on every render (still reused in components, but not optimal)
    // TODO: load it once like we do with config, but keep in lind PLs/distributors refresh when needed
    if (props.isAuthenticated) {
        // Ensure countries are loaded
        if (!props.common.countries &&
            props.common.loadCountriesRequestState !== RequestState.InProgress &&
            props.common.loadCountriesRequestState !== RequestState.Failed) {
            props.lookupCountries();
        }

        // Ensure private labels are loaded
        if (!props.common.privateLabels &&
            props.common.loadPrivateLabelsRequestState !== RequestState.InProgress &&
            props.common.loadPrivateLabelsRequestState !== RequestState.Failed) {
            props.lookupPrivateLabels();
        }

        // Ensure distributors are loaded
        if (!props.common.distributors &&
            props.common.loadDistributorsRequestState !== RequestState.InProgress &&
            props.common.loadDistributorsRequestState !== RequestState.Failed) {
            props.lookupDistributors();
        }
    }

    // Render if loading user or location is unknown
    if (props.oidc.isLoadingUser || !props.location) {
        return <div style={{ marginTop: "100px" }}><Spinner description="Loading..." /></div>;
    }

    // Render if NOT admin or supporter
    if (props.isAuthenticated && !props.isAdmin && !props.isSupporter) {
        return <UnauthorizedPage
            description="You have to be an administrator or supporter to access this site." />;
    }

    const privateLabel = getPrivateLabelConfig();

    // if user is not admin and private label don't match, show unauthorized message
    if (props.isAuthenticated && !props.isAdmin && privateLabel.id !== props.userPrivateLabelId) {
        return <UnauthorizedPage
            description="You are not authorized to access this site as a supporter as your account does not match site private label settings." />;
    }

    // dynamically load private label stylesheet
    import(`./css/pl/${privateLabel.name}/style.css`);
    createCustomDataTableThemes();

    // Render if admin or supporter
    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/faq" component={FaqPage} />
                <Route path="/connectivity" component={ConnectivityPage} />
                <Route path="/admin-tools" component={AdminToolsPage} />

                {/* Thermostats */}
                <Route path="/thermostats" component={ThermostatsList} />
                <Route path="/thermostat/query/:query" component={ThermostatsList} />
                <Route path="/thermostat/:id/audits" component={ThermostatAuditsPage} />
                <Route path="/thermostat/:id" component={ThermostatDetailsPage} />

                {/* Users */}
                <Route path="/users/new" component={CreateUser} />
                <Route path="/users/edit/:userId" component={EditUser} />
                <Route path="/users/:id/audits" component={UserAuditsPage} />
                <Route path="/users" component={UsersSearchPage} />
                <Route path="/user-info/:userId" component={UserInformationView} />

                {/* Firmware */}
                <Route path="/firmware/new" component={CreateFirmware} />
                <Route path="/firmware/edit/:firmwareId" component={EditFirmware} />
                <Route path="/firmware/visibility/:firmwareId" component={EditVisibility} />
                <Route path="/firmware/wizard/:firmwareId" component={FirmwareWizard} />
                <Route path="/firmware/sessions/retry/:sessionId" component={RetrySessionWizard} />
                <Route path="/firmware/sessions/:query" component={SessionSearchPage} />
                <Route path="/firmware/sessions" component={SessionSearchPage} />
                <Route path="/firmware/session/:sessionId" component={SessionDetailsPage} />
                <Route path="/firmware/thermostats/retry" component={RetryThermostatsWizard} />
                <Route path="/firmware/thermostats/:query" component={ThermostatSearchPage} />
                <Route path="/firmware/thermostats" component={ThermostatSearchPage} />
                <Route path="/firmware/:firmwareId" component={FirmwareDetailsPage} />
                <Route path="/firmware" component={FirmwareSearchPage} />

                {/* App Versions */}
                <Route path="/app-versions" component={AppVersionsPage} />

                {/* Private Labels */}
                <Route path="/privateLabels/new" component={PrivateLabelCreatePage} />
                <Route path="/privateLabels/edit/:privateLabelId" component={PrivateLabelEditPage} />
                <Route path="/privateLabels" component={PrivateLabelsListPage} />

                {/* Distributors */}
                <Route path="/distributors/new" component={DistributorCreatePage} />
                <Route path="/distributors/edit/:distributorId" component={DistributorEditPage} />
                <Route path="/distributors" component={DistributorsListPage} />

                {/* Email Templates */}
                <Route path="/email-templates" component={EmailTemplatesPage} />

                {/*Legal Documents*/}
                <Route path="/documents/legal/privacy" component={PrivacyPolicy} />
                <Route path="/documents/legal/terms" component={TermsOfUse} />

                {/* OIDC */}
                <Route path="/authentication/callback" component={CustomCallback} />

                {/* Default route */}
                <Route path="*" component={NotFound} />
            </Switch>
        </Layout>
    );
};

const mapStateToProps = (state: ApplicationState & { router: { location: Location } }) =>
    ({
        ...extractAuthProps(state.oidc),
        location: state.router.location,
        oidc: state.oidc,
        common: state.common
    });

export default connect(
    mapStateToProps,
    actionCreators
)(App as any);
