import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { getDataTableTheme } from "../../DataTableThemes";
import SearchRequest, { FilterTermMatch, FilterTermMatchType, defaultSearchRequest } from "../../store/search";
import { RequestState } from "../../store/sharedTypes";
import { maybePluralize } from "../../utils";
import { MessageBox, MessageBoxType, Spinner } from "../Common";
import DataTableFilterSelector from "../Common/SearchableDataTable/DataTableFilterSelector";
import DataTableSearchField from "../Common/SearchableDataTable/DataTableSearchField";
import { SearchableDataTableProps } from "../Common/SearchableDataTable/SearchableDataTable";
import { IntervalPicker, interval } from "./IntervalPicker";


export interface AuditDataTableProps {
    expandableRows: boolean;
    selectableRows: boolean;
    expandableRowsComponent?: React.ReactNode;
    thermostatSelector?: React.ReactNode;
}

export const AuditDataTable = <T extends any>(props: SearchableDataTableProps<T> & AuditDataTableProps) => {
    const [searchRequest, setSearchRequest] = useState<SearchRequest>(props.defaultSearchRequest ?? defaultSearchRequest);
    const [selected, setSelected] = useState<T[]>([]);
    const [clearRows, setClearRows] = useState<boolean>(props.clearRows ?? false);
    const [query, setQuery] = useState<string>(props.defaultSearchRequest?.query ?? "");

    const rowsPerPage = props.rowsPerPage ?? [10, 20, 50, 100, 200, 500];
    const displayText = props.displayText ?? "result";
    const pluralSuffix = props.pluralSuffix ?? "s";

    const { search } = props;

    const selectedFilters = (field: string) => {
        return searchRequest.filter.terms
            .filter(term => term.field === field)
            .map(term => term.value);
    };

    const addFilter = (field: string, value: string) => {
        const updatedSearchRequest: SearchRequest = {
            ...searchRequest, filter: {
                ...searchRequest.filter,
                terms: [...searchRequest.filter.terms, {
                    field: field,
                    value: value,
                    type: FilterTermMatchType.Equals // TODO: Allow to select this?
                }]
            }
        };
        setSearchRequest(updatedSearchRequest);
    };

    const removeFilter = (field: string, value: string) => {
        const updatedSearchRequest: SearchRequest = {
            ...searchRequest, filter: {
                ...searchRequest.filter,
                terms: searchRequest.filter.terms
                    .filter(term => term.field !== field || term.value !== value)
            }
        };
        setSearchRequest(updatedSearchRequest);
    };

    const changeLimit = (limit: number) => {
        const updatedSearchRequest: SearchRequest = {
            ...searchRequest, page: {
                ...searchRequest.page,
                size: limit
            }
        };
        setSearchRequest(updatedSearchRequest);
    };

    const changePage = (page: number) => {
        const updatedSearchRequest: SearchRequest = {
            ...searchRequest, page: {
                ...searchRequest.page,
                number: page
            }
        };
        setSearchRequest(updatedSearchRequest);
    };

    const changeSorting = (field: string, descending: boolean) => {
        const updatedSearchRequest: SearchRequest = {
            ...searchRequest, order: {
                terms: [{
                    field: field,
                    desc: descending
                }]
            }
        };
        setSearchRequest(updatedSearchRequest);
    };

    const changeInterval = (date: interval) => {
        // filter out possible previous filter object of this kind
        const terms = searchRequest.filter.terms.filter(term => term.field !== "timestamp" || term.type !== date.type);

        if (date.value) {
            const filter: FilterTermMatch = { field: date.field, type: date.type, value: date.value };
            terms.push(filter);
        }

        setSearchRequest({ ...searchRequest, filter: { ...searchRequest.filter, terms } });
    };

    React.useEffect(() => {
        search(searchRequest);
    }, [search, searchRequest]);

    createTheme("solarized", {
        text: {
            primary: "black",
        },
        background: {
            default: "rgba(0,0,0,0)",
        }
    });

    return (
        <>
            <div className="d-flex flex-column">
                <div className="d-flex" style={{ maxWidth: "800px" }}>
                    <DataTableSearchField
                        query={query}
                        displayText={`${displayText}${pluralSuffix}`}
                        disableButton={props.state === RequestState.InProgress}
                        setQuery={(q) => setQuery(q)}
                        search={() => setSearchRequest({ ...searchRequest, query: query })} />
                </div>
                <div className="d-flex flex-wrap mt-2">
                    <div>{props.filters?.map(filter =>
                        <DataTableFilterSelector
                            key={filter.field}
                            field={filter.field}
                            selected={selectedFilters(filter.field)}
                            values={filter.values}
                            disableButton={props.state === RequestState.InProgress}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            display={filter.display} />
                    )}</div>
                    <IntervalPicker changeInterval={changeInterval} />
                    <div className="d-flex m-2" style={{ height: "fit-content" }}>
                        {props.thermostatSelector}
                    </div>
                </div>
            </div>
            {props.state === RequestState.Failed ? (
                <MessageBox type={MessageBoxType.Error} title="Failed to search" description={`An error occurred when searching ${displayText}${pluralSuffix}`}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={() => search(searchRequest)}>Try again</button>
                    <button
                        className="btn btn-primary mt-3 ml-3"
                        onClick={() => search(defaultSearchRequest)}>Reset search</button>
                </MessageBox>
            ) :
                (
                    <DataTable
                        // Options
                        keyField={"id"}
                        noHeader={!props.response}
                        title={!props.response ? null : `Found ${maybePluralize(props.response.total, displayText, pluralSuffix)}`}
                        columns={props.columns}
                        data={!props.response ? [] : props.response.items}
                        noDataComponent={<p> </p>}
                        // Pagination
                        pagination
                        paginationServer
                        paginationPerPage={searchRequest.page.size}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationTotalRows={!props.response ? 0 : props.response.total}
                        onChangeRowsPerPage={size => changeLimit(size)}
                        // page index in API is zero based but in react-data-table-component it starts from 1
                        onChangePage={page => changePage(page - 1)}

                        // Sorting
                        onSort={(column, direction) => changeSorting(column.selector as string, direction === "desc")}
                        sortServer

                        // Selectable rows
                        clearSelectedRows={clearRows}
                        onSelectedRowsChange={({ selectedRows }) => {
                            setSelected(selectedRows);
                            setClearRows(false);
                        }}
                        selectableRows={props.selectableRows ?? true}
                        selectableRowsHighlight
                        selectableRowsNoSelectAll={props.hideShowAll}

                        // Expandable row
                        expandableRows={props.expandableRows ?? false}
                        expandableRowsComponent={props.expandableRowsComponent}

                        // Loading/progress
                        progressPending={props.state === RequestState.InProgress}
                        progressComponent={<Spinner />}

                        // Actions
                        contextComponent={props.contextComponent ? props.contextComponent(selected, () => setClearRows(true)) : <p></p>}

                        // Theme
                        theme={props.transparent ? "solarized" : getDataTableTheme()}
                    />)}
        </>
    );
};

export default AuditDataTable;