import React, { useCallback, useRef } from "react";
import { IconContext } from "react-icons";
import { FaRegWindowClose } from "react-icons/fa";
import { connect } from "react-redux";
import { ApplicationState, RequestState } from "../../store";
import { actionCreators } from "../../store/Audits/actionCreators";
import { ThermostatListItem } from "../../store/ThermostatsList/state";
import SearchRequest from "../../store/search";
import { Page, withAuthProps, withCommonProps } from "../Common";
import { TabButton } from "../Common/TabButton";
import { AuditDataTable } from "./AuditDataTable";
import { Message } from "./Message";
import { ThermostatAuditsPageProps, auditFilters, columns, defaultAuditSearch } from "./ThermostatAuditsPage";

const UserAuditsPage = (props: ThermostatAuditsPageProps) => {
    const pageTitle = "User Audits";

    const [thermostats, setThermostats] = React.useState<ThermostatListItem[]>([]);
    const [selectedThermostats, setSelectedThermostats] = React.useState<ThermostatListItem[]>(thermostats);

    const loadAuditsCallback = useCallback((request: SearchRequest) => {
        const ids = selectedThermostats.length ? selectedThermostats.map(x => x.id) : thermostats.map(x => x.id);
        if (selectedThermostats.length) {
            props.loadAudits(request, ids);
        } else{
            props.loadUser(request, props.match.params.id);
        }
    }, [selectedThermostats, thermostats]);

    const thermostatSelector = useRef<HTMLButtonElement>(null);

    // fetch multiple thermostats if audits are to be aggregated by user
    React.useEffect(() => {
        props.changeThermostatSearchRequest({ ...props.thermostatsSearchRequest, query: props.match.params.id });
        props.loadThermostats();
        // if the button is still conditionally rendered after 3 sec, it indicated the lack of thermostats
        setTimeout(() =>{
            if (thermostatSelector.current) {
                thermostatSelector.current.innerHTML = "No thermostats found!";
            }
        }, 1500);
    }, [props.match.params.id]);

    React.useEffect(() => {
        if (props.searchRequestState === RequestState.Succeeded) {
            const fetched = props.thermostatsResult?.items ?? [];
            setThermostats(fetched);
        }
    }, [props.searchRequestState]);

    const toggleThermostat = (thermostat: ThermostatListItem) => {
        if (selectedThermostats.map(x => x.id).includes(thermostat.id)) {
            const filtered = selectedThermostats.filter(t => t.id !== thermostat.id);
            setSelectedThermostats(filtered);
        } else {
            setSelectedThermostats([...selectedThermostats, thermostat]);
        }
    };

    const printId = (thermostat: ThermostatListItem) => (selectedThermostats.map(x => x.id).includes(thermostat.id)
        ? thermostat.name
        : thermostat.name.slice(0, 10).concat(thermostat.name.length > 10
            ? "..." : ""
        ));

    const selector = thermostats.length ? <>
        {thermostats.map(thermostat =>
            <TabButton style={{ width: "min-content", minWidth: "fit-content" }} text={printId(thermostat)}
                active={selectedThermostats.map(x => x.id).includes(thermostat.id)}
                onClick={() => toggleThermostat(thermostat)}/>)
        }
        <button className="btn ml-2 btn-danger" style={{ width: "min-content" }} type="button" onClick={() => setSelectedThermostats([])}><FaRegWindowClose size={18}/></button>
    </> :
        <button className="btn btn-primary" type="button" disabled ref={thermostatSelector}>
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
            Loading...
        </button>;
    return <IconContext.Provider value={{ className: "header-react-icons" }}>
        <Page id="thermostats-page" title={pageTitle}>
            <AuditDataTable
                state = {props.auditSearchRequestState}
                columns={columns}
                response = {props.searchResult}
                search = {loadAuditsCallback}
                defaultSearchRequest={defaultAuditSearch}
                filters={auditFilters}
                thermostatSelector={selector}
                displayText={"Audit"}
                pluralSuffix={"s"}
                expandableRows
                expandableRowsComponent={<Message/>}
                selectableRows={false}
            />
        </Page>
    </IconContext.Provider>;
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => {
        return { ...state.thermostatAudits,
            ...state.thermostatsList };
    },
    actionCreators,
)(UserAuditsPage as any)));