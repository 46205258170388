import React from "react";

export const MaskedMessage = (props: {text: string}) => {
    const maskGradient = `linear-gradient(
        to right,
        rgba(0,0,0, 1) 0,
        rgba(0,0,0, 1) 90%,
        rgba(0,0,0, 0) 99%,
        rgba(0,0,0, 0) 0)`;

    const messageMask : React.CSSProperties = {
        WebkitMask: maskGradient,
        mask: maskGradient,
        overflowY: "hidden",
    };

    const limit = 200;
    let message = props.text;
    const applyMask = () : boolean => {
        if (props.text.length > limit) {
            message = props.text.slice(0, limit).concat("...");
            return true;
        }

        return false;
    };

    return <span style={applyMask() ? {} : {}}>{message}</span>;
};