import React from "react";
import { IDataTableColumn } from "react-data-table-component";
import { IconContext } from "react-icons";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { ApplicationState, IAuthProps } from "../../store";
import { actionCreators } from "../../store/Audits/actionCreators";
import { AuditDetails, AuditState } from "../../store/Audits/state";
import { CommonState } from "../../store/Common/state";
import { ThermostatsListState } from "../../store/ThermostatsList/state";
import SearchRequest, { FilterTermMatchType, defaultSearchRequest } from "../../store/search";
import { formatDateTime } from "../../utils";
import { withAuthProps, withCommonProps } from "../Common";
import { Page } from "../Common/Page";
import { DataTableFilterSelectorDetails } from "../Common/SearchableDataTable/SearchableDataTable";
import AuditDataTable from "./AuditDataTable";
import { LevelChip } from "./LevelChip";
import { MaskedMessage } from "./MaskedMessage";
import { Message } from "./Message";
import { auditLoggingInterval } from "./IntervalPicker";

export type ThermostatAuditsPageProps =
    AuditState &
    ThermostatsListState &
    typeof actionCreators &
    CommonState &
    IAuthProps &
    RouteComponentProps<{ id: string }>;

const today = new Date();
const defaultFrom = new Date();
defaultFrom.setDate(today.getDate() - auditLoggingInterval);

export const defaultAuditSearch: SearchRequest = {
    ...defaultSearchRequest,
    order: {
        terms: [{
            field: "@timestamp",
            desc: true
        }]
    },
    filter: {
        ...defaultSearchRequest.filter,
        terms: [
            { field: "timestamp", type: FilterTermMatchType.LessThanOrEquals, value: today.toISOString() },
            { field: "timestamp", type: FilterTermMatchType.GreaterThanOrEquals, value: defaultFrom.toISOString() }
        ]
    }
};

const createdAtFormat = (audit: AuditDetails) => formatDateTime(audit.timestamp as Date);

export const auditLevelValues = ["Information", "Warning", "Error", "Fatal"];
export const auditFilters: DataTableFilterSelectorDetails[] = [{ field:"level", values: auditLevelValues }];

export const columns: IDataTableColumn[] = [
    { name: "Created at", selector: "timestamp", sortable: true, maxWidth: "250px",
        cell:createdAtFormat },
    { name: "Level", selector: "level", sortable: true, maxWidth: "150px",
        cell: (row: AuditDetails) => <LevelChip text={row.level} /> },
    { name: "Message", selector: "message", sortable: true, maxWidth: "1400px",
        cell: (row: AuditDetails) => <MaskedMessage text={row.message}/> }
];

const ThermostatAuditsPage = (props: ThermostatAuditsPageProps) => {
    const pageTitle = "Thermostat Audits";

    const loadAuditsCallback = React.useCallback((request) => {
        props.loadAudit(request, props.match.params.id);
    }, [props.match.params.id]);

    return <IconContext.Provider value={{ className: "header-react-icons" }}>
        <Page id="thermostats-page" title={pageTitle}>
            <AuditDataTable
                state = {props.auditSearchRequestState}
                columns={columns}
                response = {props.searchResult}
                search = {loadAuditsCallback}
                defaultSearchRequest={defaultAuditSearch}
                filters={auditFilters}
                displayText={"Audit"}
                pluralSuffix={"s"}
                expandableRows
                expandableRowsComponent={<Message/>}
                selectableRows={false}
            />
        </Page>
    </IconContext.Provider>;
};

export default withCommonProps(withAuthProps(connect(
    (state: ApplicationState) => {
        return { ...state.thermostatAudits,
            ...state.thermostatsList };
    },
    actionCreators,
)(ThermostatAuditsPage as any)));