import React from "react";
import { AuditDetails } from "../../store/Audits/state";
import { prettifyJSON } from "../../utils";

export const Message = (props: {data?: AuditDetails})=>{
    const underline : React.CSSProperties = {
        display: "inline-block",
        backgroundColor: "lightgray"
    };

    const splittedMessage = prettifyJSON(props.data?.message ?? "");
    return (
        splittedMessage.length > 1 ?
            <span className="m-2 pl-2">{splittedMessage[0]}
                <pre style={underline}>{splittedMessage[1]}</pre>
                {splittedMessage[2]}</span>
            : <span className="m-2 p-2">{splittedMessage[0]}</span>
    );
};