import { auditLevelValues } from "./ThermostatAuditsPage";
import React from "react";

export const chipColors = ["RoyalBlue", "orange", "Tomato", "black"];
export const LevelChip = (props: {text: string}) => {
    const findChipColor = () => {
        const idx = auditLevelValues.findIndex((val) => val === props.text);
        return idx >= 0 && idx < 3 ? chipColors[idx] : "grey";
    };

    const levelChip : React.CSSProperties = {
        minWidth: "100px",
        padding: "5px",
        borderRadius: "10px",
        backgroundColor: findChipColor(),
        color: "white",
        textAlign: "center"
    };

    return <span style={levelChip}>{props.text}</span>;
};